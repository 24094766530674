
.vgst-title {
	padding-top: 10px;
	height: 48px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 134.5%;
	color: #344f5d;
	margin-left: 3px;
}


.vgst-individual,
.vgst-corporate {
	height: 70px;
	width: 410px;
	border-radius: 0px;
	background: #344f5d;
	box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 134.5%;
	display: flex;
	align-items: center;
	color: #ffffff;
	margin-right: 2%;
	justify-items: auto;
	justify-content: space-between;
}

.vgst {
	// padding: 50px;

	.vgst-title {
		padding-top: 10px;
		height: 48px;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 36px;
		line-height: 134.5%;
		color: #344f5d;
		margin-left: 3px;
	}

	.vgst-form-description {
		margin-top: 30px;
		margin-bottom: 28px;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 134.5%;
		color: #c70e0e;
	}

	.type-select {
		// margin-left: auto;

		.vgst-individual,
		.vgst-corporate {
			height: 70px;
			width: 410px;
			border-radius: 0px;
			background: #344f5d;
			box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);
			font-family: "Poppins";
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 134.5%;
			display: flex;
			align-items: center;
			color: #ffffff;
			margin-right: 2%;
			justify-items: auto;
			justify-content: space-between;
		}
	}
	.vgst-form-view {
		margin-left: -25px;
		margin-top: 16px;
	}

	.menu-list-item {
		width: 58px;
		height: 22px;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 134.5%;
		color: #283237;
	}
}

.overview-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 134.5%;
	color: #ffffff;
}

.overview-amount {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 134.5%;
	color: #ffffff;
}

.dashboard-view {
	margin-left: -10px;
}

.dashboard-vertical-sidebar {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 134.5%;
	/* or 54px */

	color: #9d7c40;
}

.create-will {
	width: 100%;
	background: #344f5d;
	box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);
	justify-content: space-between;
	align-items: center;
	padding-left: 30px;
	padding-right: 30px;
	height: 71px;

	.create-will-text {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 134.5%;
		padding-top: 15px;

		/* or 27px */

		color: #ffffff;

		a {
			text-decoration: none;
			color: #ffffff;
		}
	}
}

.will-description {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #344f5d;
	margin-bottom: 50px;
}

.will-list-options li {
	margin-bottom: 20px;
	list-style-type: disc;
}

.btn-next,
.btn-previous {
	height: 40px;
	width: 181px;
	border-radius: 0px;
	background: #344f5d;
	color: white;
}

.form-table {
	background: rgba(255, 255, 255, 0.24);
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
}

.rt-td {
	border-bottom: 0px solid;
	text-align: center;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 134.5%;
	/* or 16px */

	color: #344f5d;
}

.will-payment-desc {
	justify-content: space-between;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
	/* identical to box height */
	align-items: center;
	color: #344f5d;
}

.will-pay-footer {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;

	color: #9d7c40;
}

.personal-prop-select:hover,
.personal-prop-select:focus {
	box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
	transform: translateY(-0.35em);
}

.vgst-inactive-grayout {
	background: #c4c4c4;
	box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);

	height: 70px;
	width: 410px;
	border-radius: 0px;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 134.5%;
	display: flex;
	align-items: center;
	color: #ffffff;
	margin-right: 2%;
	justify-items: auto;
	justify-content: space-between;
}
