@tailwind base;
@tailwind components;
@tailwind utilities; 


*{
    font-family: Poppins ;
}
.displaySidebar{
left:300px;
width:0px;
}

.topbar-fixed{
    position: fixed !important;
    width: 100% !important;
}

.vetivaBrownText{
    color: #9d7c40 !important;
}


.vgst-block{
    background: #c4c4c4;
	box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);

	height: 70px;
	/* min-width: 50%; */
	/* width: 100%; */
	border-radius: 0px;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	/* font-size: 20px; */
	line-height: 134.5%;
	display: flex;
	align-items: center;
	color: #ffffff;
	margin-right: 2%;
	justify-items: auto;
	justify-content: space-between; 
}