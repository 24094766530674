html,
body {
  height: 100%;
}
* {
  margin: 0;
}

/*General font-size classes*/
.f-12{
  font-size: 12px;
}

.f-16{
  font-size: 16px;
}


/* New CSS Designs */

/* Navigation Style */

.nav-col {
  background-color: white;
}

.nav-link-tag {
  /* font-size: 14px;
  font-weight: 600;
  color: black !important;
  */
  padding: 20px 25px !important; 
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: #000000 !important;
}

.signin{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  padding: 20px 35px !important; 
  color: #000000;
}

.createacct{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: #ffffff;
}


.nav-button {
  background-color: #c49d27;
  color: white;
  border-color: transparent;
}
.nav-button:hover {
  background-color: #c49d27;
  border-color: transparent;
}


/* Page Index */

/* ABout pages style */
.bk-img2-div2 {
  /* margin-left: 20%;  */
  width: 30%;
  padding-top: 8px;
  margin-left: 40px;
}

.about-sect{
  width: 100%;
  height: 580px;
}

.about-txt-wrapper{
  margin-top: 105px !important;
  margin-left:px !important;
}

.about-div-p{
  width: 479px;
  color: #ffffff;
}

.aboutus-image{
  width: 420px;
  height: 560px;
  border-radius: 21px;
  margin-top: -30px;
  margin-left: 80px !important;
  z-index: 5 !important;
}

.aboutus-img-div{
  display: none;
}

.aboutus-img-md{
  width: 296px;
  height: 165px;
  border-radius: 5px;
}

.bk-img3-btn {
  text-decoration: none;
  background-color: #BE9E41;
  color: #ffffff;
  padding: 10px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 3%;
}
.row-contain {
  padding-left: 135px;
}

.pageIndex-img {
  margin-top: -50px;
  margin-bottom: 70px;
  /* width: 80%; */
  width: 420px;
  border-radius: 21px !important;
  /* width: 90vh; */
  width: 420px;
}
.row-contain-div1 {
  /* padding-top: 100px; */
  height: 540px;
}
.row-container-h1 {
  font-family: 'Poppins' sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
}
.row-contain-div1-p {
  font-weight: 400;
  line-height: 24.43px;
  font-size: 14px;
  font-style: normal;
  color: #FFFFFF;
  line-height: 174.5%;
}

/* Footer Style */
.footer-h5 h6 {
  color: black;
  font-weight: 500;
  font-size: 15px;
  padding-top: 13px;
}
.h6-footer {
  color: black;
  font-weight: 500;
  font-size: 14px;
  padding-top: 13px;
}
.bkg-white {
  background-color: white;
}
.span-v {
  color: #BE9E41;
}
.span-footer {
  color: black;
  font-size: 14px;
  padding-top: 13px;
  font-weight: 300;
}

.footer-th{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 123.5%;
  /* or 17px */
}

.footer-txt{
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 112.96% !important;
}

.span-footer2 {
  color: black;
  font-size: 14px;
  padding-top: 13px;
  font-weight: 300;
}
.container-image {
  padding-bottom: 50px;
}
.icon-foot {
  background-color: black;
  color: black;
}
.footer-add {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  font-weight: bold;
  font-size: 15px;
  padding-top: 13px;
}
.copyright-footer {
  color: black;
  padding-top: 30px;
  padding-bottom: 95px;
}

/* vetp Services Page */
.vetp-service-BImg {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/newVetpBKImage.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  height: 180vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}
.list-ul {
  list-style-type: none;
}
.cards-listing {
  margin-bottom: -5px;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins' sans-serif;
  color: #FFFFFF;
  font-weight: 300;
}
.hr-style {
  margin-top: 2px;
  margin-right: 30px;
}
.card-head-body1 {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/card-img.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  margin-top: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 278px;
  height: 320px;
  border-radius: 4px;
  margin-left: 520px !important;
  margin-top: 30px;
  margin-bottom: 5% !important;
}
.card-head-body2 {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/card-img.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  margin-top: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 278px;
  height: 320px;
  border-radius: 4px;
  margin-left: 240px !important;
  margin-top: 130px !important;
}
.card-head-body3 {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/card-img.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  margin-top: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 278px;
  height: 320px;
  border-radius: 4px;
  margin-left: 520px !important;
  margin-top: -100px !important;
}
.card-head-body4 {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/card-img.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  margin-top: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 278px;
  height: 320px;
  border-radius: 4px;
  margin-left: 240px !important;
  margin-top: -5px !important;
}
.card-body-p {
  margin-left: 10px !important;
  margin-top: -25px;
}
.vetp-services-btn {
  text-decoration: none;
  background-color: #283237;
  color: white !important;
  padding: 15px 15px;
  font-size: 20px;
  margin-left: 13%;
  margin-top: -1%;
  width: 270px;
}
.vetp-service-BImg h1 {
  margin-left: 13%;
  padding-top: 100px;
  width: 600px;
  color: #46345E;
  font-weight: 400;
  font-family: 'Poppins' ;
  line-height: 76.8px;
  font-size: 64px;
}
.vetp-service-BImg h4 {
  margin-left: 13%;
  padding-top: 15px;
  width: 657px;
  color: #46345E;
  font-weight: 400;
  font-family: 'Poppins' ;
  line-height: 30px;
  font-size: 20px;
} 
.vetp-service-BImg p {
  margin-left: 13%;
  width: 450px;
  color: #000000;
  font-weight: 400;
  font-family: 'Poppins';
  line-height: 24px;
  font-size: 16px;
}
.vetp-service-BImg h3 {
  margin-left: 13%;
  width: 446px;
  padding-top: 30px;
  color: #000000;
  font-weight: 500;
  font-family: 'Poppins';
  line-height: 48px;
  font-size: 32px;
}

/* write a will services page */

.will-services-page {
  display: flex;
  justify-content: space-between;
}
.will-services-page h1 {
  margin-left: 5%;
  padding-top: 7%;
  width: 528px;
  color: #344F5D;
  font-weight: 400;
  font-family: 'Poppins';
  line-height: 72px;
  font-size: 48px;
}
.will-services-page p {
  margin-left: 5%;
  color: #000000;
  Width: 657px;
  font-weight: 400;
  font-family: 'Poppins';
  line-height: 27.68px;
  font-size: 16px;
  Height: 168px;
}
.will-service-BackgroundImg {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/youngman-black.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  height: 800.855px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.will-service-BackgroundImg h3 {
  margin-left: 40%;
  padding-top: 15px;
  color: #344F5D;
  font-weight: 500;
  font-family: 'Poppins';
  line-height: 54px;
  font-size: 36px;
}
.card-bg {
  background-color: transparent;
}
.get-started-data{
  font-family: Poppins;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #344F5D;
  width: 239px;
  padding-top: 40%;
}
.card-div-style {
  width: 100%;
  height: 260px;
  margin-left: 40% !important;
  border-radius: 10px;
  padding: 30px;
}
.count-number {
  padding-top: 40%;
  color: #344F5D;
  font-weight: 400;
  font-family: 'Poppins';
  line-height: 36px;
  margin-left: 55%;
  font-size: 24px;
  line-height: 36px;
}
.headings {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #344F5D;
  margin-left: 55%;
  width: 106px;


}
.servicewill-h2 {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  width: 150px;
  font-family: 'Poppins';
  margin-left: 55%;
}
.service-will-card-div {
  width: 422px;
  margin-left: 60% !important;
  border-radius: 10px;
  height: 400px;
}
.service-will-h4 {
  padding: 30px 35px 20px 35px;
  color: #344F5D;
  font-weight: 400;
  font-family: 'Poppins';
  line-height: 30px;
  font-size: 20px;
}
.service-will-ul {
  margin-left: 25px;
  padding-right: 28px;
  padding-bottom: 40px;
  color: #000000;
  font-weight: 300;
  font-family: 'Poppins';
  line-height: 22px;
  font-size: 12px;
}
.service-will-btn {
  text-decoration: none;
  background-color: rgb(49, 47, 47);
  color: white !important;
  padding: 13px 55px;
  font-size: 20px;
  margin-left: 63%;
  margin-top: -4%;
}


/* Signin Page */
.signin-flex {
  display: flex;
  justify-content: space-around;
}
.signin-h6 {
  color: #000000;
  font-weight: 600;
  font-family: 'Poppins' sans-serif;
  font-size: 28px;
  line-height: 37.66px;
  margin-top: 200px;
  width: 153px;

}
.signin-logo {
  Width: 152px;
  Height: 45px;
  Margin-top: 86px;
  margin-left: 650px;
}
.signin-h1 {
  color: #000000;
  font-weight: 600;
  font-family: 'Poppins' sans-serif;
  font-size: 28px;
  line-height: 37.66px;
  margin-top: 50px;
}
.signin-div2 {
  margin-left: -1100px !important;
}
.form-color {
  padding-top: 5%;
  color: #000000 !important;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.P-text-signin {
  color: #000000 !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.P-text-signin2 {
  color:#000000 !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 18.83px;
}
.signIn-formButton {
  width: 100%;
  padding: 1em 1em;
  background-color: #9D7C40;
  outline: none;
  border: none;
  color: #FFFFFF !important;
  font-family: 'Poppins';
  /* font-weight: 500; */
  /* font-size: 20px; */
  /* line-height: 26.9px; */
}
.siginbk {
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/SignInBackground.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
  height: 115vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
}
.signupIconsP {
  font-family: 'Poppins';
  color: #000000;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}
.forget-pass {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}

/* staff share scheme  */
.SSS-banner{
    background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/white-sss-banner.png"); /*../images/Images//Screenshot\ 2022-04-11\ 211729.png*/
    height: 630px;
    margin-top: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}
.SSS-banner > h1 {
  color: #46345E;
  font: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 77px;
  width: 366px;
  margin-left: 180px;
  padding-top: 160px;
}
.SSS-banner > p {
  color: #000000;
  font: 'Poppins';
  width: 498px;
  margin-left: 180px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */
  color: #000000;
}
.sss-sect-page {
  background-color: #283237;
}
.sss-display {
  display: flex;
  justify-content: space-around;
  height: 350px;
}
.sss-display-div1 {
  margin-left: 160px;
  margin-top: -90px;
}
.sss-display-div2 {
  color: #FFFFFF;
  font: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 380px;
  padding-top: 64px;
  margin-left: -200px;
}
.sss-display-btn {
  width: 280px;
  height: 60px;
  padding: 0.8em 0.6em;
  background-color: #9D7C40;
  outline: none;
  border: none;
  color: #FFFFFF !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

/* sidebar for dashboard */

.profile-fname{
  margin-left: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 36px !important;
  line-height: 134.5% !important;
  color: #344F5E !important;
}

.profile-lname{
  margin-left: 10px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  line-height: 134.5% !important;
  color: #344F5E !important;
}

.sidebar-menus{
  font-style: normal;
font-weight: 400;
font-size: 16px;
}

/* Dashboard */
.dashboard-th{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 134.5%;
  color: #9D7C40;
}

.acct-bal-val{
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 134.5%;
  color: #9D7C40;
}

.th-text-black{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 134.5%;
  /* or 16px */
  color: #000000;
}

.th-text-white{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 134.5%;
  /* or 16px */
  color: #ffffff;
}

.trans-overview-text{
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 134.5%;
  /* or 43px */
  color: #FFFFFF;
}

.trans-date-select, option {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 134.5%;
  /* identical to box height, or 22px */
  color: #000000;
}

/* onboarding forms vgst, vetp, share scheme, will drafting */
.form-sect-title{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 134.5%;
  /* identical to box height, or 22px */
  color: #515151;
}

.important-text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 134.5%;
  /* or 19px */
  color: #C70E0E;
}

.form-subhead-text{
  font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
/* identical to box height */
color: #000000;
}

.clicked{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 134.5%;
  color: #ffffff;
  background: #C4C4C4 !important;
  box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);
}

/* Will dashboard */
.create-will{
  height: 70px;
  background: #344F5D;
  color: #ffffff;
  box-shadow: 0px 4px 25px rgba(157, 124, 64, 0.1);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.create-will-text{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}

.borderbox{
  margin: 0 !important;
  padding:0 !important;
}

.subtitle{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 196%;
  /* or 24px */
  color: #000000;
}

/* header image css */
.header-image-div{
  background-image: url("../images/Images/ItrustWebAsset/ItrustWebAsset/Pictures/headerImage.png");
  min-height:540px !important;
  min-width: 1440px;
  background-repeat: no-repeat;
  background-size:cover ;
  background-position: center;
}

/*new css - home screen */
.home-banner{
  background-image: url("../images/svg/homeline.svg");
  min-height: 540px;
  background-color:#ffffff !important;
  min-width: 1440px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: -1;
}

.home-banner-text1,
.home-banner-text2,
.home-banner-btn
{
  margin-left: 39px;
}

.home-banner-text1{
width: 361px;
height: 158px;
font-style: normal;
font-weight: 300;
font-size: 58px;
line-height: 136%;
/* or 79px */
color: #000000;
}

.home-banner-text2{
  width: 347px;
  height: 72px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.banner-div1{
  width:810px;
  position: relative;
}

.banner-div2{
  margin-top: 101px;
  width:630px;
}

.banner-img1{
  position: absolute;
  height: 216.83px;
  width: 216.83px;
  left: 130px;
  top: 47.92px;
}

.banner-img2{
  position: absolute;
  height: 179.74px;
  width: 179.74px;
  left: 417.59px;
  top: 84.89px;
}

.banner-img3{
  position: absolute;
  height: 215.69px;
  width: 215.69px;
  left: 261.47px;
  top: 264.63px;
}

.banner-img4{
  position: absolute;
  height: 269.16px;
  width: 269.16px;
  left: 540.84px;
  top: 245.18px;
}

.home-banner-btn{
  text-decoration: none;
  background-color: #283237;
  color: white !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: 165px !important;
  height: 40px !important;
  margin-top: 24px;
}

/* new css - vetp screen, private trust, will drafting & sharescheme */
.vetp-banner,
.ptrust-banner,
.will-banner,
.share-banner
{
  position: relative;
  background-image: url("../images/svg/subpageline.svg");
  min-height: 932px;
  background-color:none !important;
  min-width: 1440px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
}

.banner-div-center{
  position: relative;
  width: 1440px;
}

.vetp-banner-txt-div,
.ptrust-banner-txt-div,
.will-banner-txt-div,
.share-banner-txt-div
{
  position: absolute;
  margin-top: 101px;
  left: 730px;
}

/* Below banner-img css serves vetp, private trust & sharescheme ball(image) */
.vetp-banner-img1,
.ptrust-banner-img1,
.will-banner-img1,
.share-banner-img1
{
  position: absolute;
  height: 473px;
  width: 473px;
  left: 180px;
  bottom:380px;
}

.vetp-banner-img2,
.ptrust-banner-img2,
.will-banner-img2,
.share-banner-img2
{
  position: absolute;
  height: 217px;
  width: 217px;
  left: 552px;
  bottom: 200px;
}

.vetp-banner-img3,
.ptrust-banner-img3,
.will-banner-img3,
.share-banner-img3
{
  position: absolute;
  height: 215.69px;
  width: 215.69px;
  left: 261.47px;
  bottom: 76.59px;
}

.vetp-banner-img4,
.ptrust-banner-img4,
.will-banner-img4,
.share-banner-img4
{
  position: absolute;
  height: 269.16px;
  width: 269.16px;
  left: 841px;
  bottom: 109.84px;
}



.vetp-banner-text1,
.ptrust-banner-text1,
.will-banner-text1,
.share-banner-text1
{
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
}

.vetp-banner-text2,
.ptrust-banner-text2,
.will-banner-text2,
.share-banner-text2
{
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 15px;
}

.ptrust-banner-text1{
  font-size: 48px;
  line-height: 137%;
}

.ptrust-banner-text2{
  width: 480px;
}

.will-banner-text1{
  width: 423px;
}

.will-banner-text2{
  width: 401px;
}

.share-banner-text1{
  font-size: 48px;
  line-height: 137%;
  width: 372;
}

.share-banner-text2{
  width: 500px;
  line-height: 160%;
}



.vetp-banner-btn,
.ptrust-banner-btn,
.will-banner-btn,
.share-banner-btn
{
  text-decoration: none;
  background-color: #283237;
  color: white !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: 165px !important;
  height: 40px !important;
  margin-top: 25px;
}


/*media query*/
 @media only screen and (max-width: 768px) {
  /* For mobile {aboutus section}: */


  
} 



