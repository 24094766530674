body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #main-wrapper{
    height:auto !important;
    overflow-y: hidden !important;
  }
  .App {
    display: flex;
    height: 100vh;
    /* width:  */
  }
/*   
  position: fixed;
  z-index: 50;
  background: #fff;
  height: 64px;
  width: 100%; */
  .content {
    padding: 40px 60px;
  }
  
  /* FADE ANIMATIONS */
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
  
  /* SIDEBAR */
  
  .sidebar__button {
    background: transparent;
    padding: 10px;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    transition: 0.3s linear;
  }
  .selected{
    background:#9d7c40;
  }
  .selected i, .selected span{
    color:white !important;

  }

  .navbar-when-sidebar-isopen{
    width: -webkit-calc(100% - 250px)  !important;
    width:    -moz-calc(100% - 250px) !important;
    width:    calc(100% - 250px) !important;
  }
  

  .sidebar {
    width: 250px;
    background: white;
    padding: 20px 0px;
    color: black;
    transition: 0.4s ease-in;
    z-index: 5;
    
    
    -webkit-box-shadow: 6px 3px 10px -13px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 3px 10px -13px rgba(0,0,0,0.75);
    box-shadow: 6px 3px 10px -13px rgba(0,0,0,0.75);
  }
  
  .sidebar-closed {
    min-width: 0px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar__listItem {
    margin: 30px 10px;
    display: flex;
    height: 20px;
  }
  
  .sidebar__listItem span {
    white-space: nowrap;
  }
  
  .sidebar__icon {
    margin: 0 15px 0 0;
  }
  
   /* SLIDER ANIMATIONS */
   .slider-enter {
    margin-left: 0px;
  }
  
  .slider-enter-active {
    margin-left: 0px;
    /* transition: margin 400ms; */
    transition: margin 1s ease-in-out;

  }
  
  .slider-exit {
    margin-left: 250px;
  }
  
  .slider-exit-active {
    margin-left: 250px;
    /* all 1s ease-in-out; */
    transition: margin 1s ease-in-out;
  }
/* 
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
   */